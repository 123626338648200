

































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SessionsToggle extends Vue {
  @Prop({ default: () => false }) isOtherUser!: boolean;

  selectedTab = 0;
  changeRoute() {
    const path = this.$router.currentRoute.fullPath;
    if (this.selectedTab === 1 && !path.endsWith("progress")) {
      const newPath = path.replace("list", "progress");
      this.$router.replace(newPath);
    } else if (this.selectedTab === 0 && !path.endsWith("/list")) {
      const newPath = path.replace("progress", "list");
      this.$router.push(newPath);
    }
  }
  changeTab(index: number) {
    this.selectedTab = index;
    if (!this.isOtherUser) this.changeRoute();
    else this.$emit("change", index);
  }

  @Watch("$route", { immediate: true })
  loadTab() {
    if (this.$route.path.endsWith("list")) this.changeTab(0);
    else if (this.$route.path.endsWith("progress")) this.changeTab(1);
  }
  @Watch("selectedTab", { immediate: true })
  tabChanged() {
    this.$emit("change", this.selectedTab);
  }
}
