















import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component
export default class SessionLimitControl extends Vue {
  @Prop({ default: () => 0 }) length!: number;
  @PropSync("limitProp", { default: () => 0 }) limit!: number;

  get values() {
    let len = this.length;
    if (len < 2) return [];
    const vals = new Array<number>(Math.floor(len / 2))
      .fill(0, 0)
      .map((_, i) => (i + 1) * 2);
    return len % 2 ? vals : [...vals, len];
  }
}
